import React from 'react';


const FirstAidKit = () => {

    return (
        <div className="container">
            <p className="pt-3"><b>Аллергия (отек Квинке):</b> Преднизалон (в холодильнике в конце коридора на дверце)
                (дексаметазон) 2куба в мыщцу.</p>
            <p><b>Прединфаркт:</b> нитроглицерин под язык 1шт, через 5 мин можно еще. (Нельзя при низком давлении)</p>
            <p><b>Давление:</b> выше 150 моксонидин (каптоприл) ½ таблетки под язык (15 мин).
                Давление ниже 90, кофеин (чай крепкий, ноги вверх, цитрамон)</p>
            <p><b>От головы:</b> анальгин, темпалгин, цитрамон</p>
            <p>Если что-то взяли из аптечки не забудьте потом вернуть</p>
            <a href="javascript:history.back()" className="btn btn-success btn-lg btn-sm mb-2">Назад</a>
        </div>

    )
        ;
};

export default FirstAidKit;
